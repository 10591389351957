import * as React from "react";
import "@aws-amplify/ui/dist/style.css";
import Teaser from "components/pages/front/Teaser";
import Layout from "components/layouts/Common";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
type Props = {
  pageContext: any;
};

export default function App(props: Props) {
  const { pageContext } = props;
  const { show } = pageContext;
  return (
    <div className="App">
      {show && (
        <GatsbySeo
          title={show.title}
          description={show.description}
          openGraph={{
            url: `https://www.onlineboatshow.jp/${show.slug}`,
            title: show.title,
            description: show.description,
            site_name: "ONLINE BOATSHOW オンラインボートショー",
            images: [
              {
                url: show.ogpImage ? show.ogpImage.url : "",
                alt: show.title,
              },
            ],
          }}
        />
      )}
      <Helmet>
        <title>{show && show.title ? show.title : "ONLINE BOATSHOW"}</title>
      </Helmet>
      <Layout show={show}>
        <Teaser show={pageContext.show} />
      </Layout>
    </div>
  );
}
